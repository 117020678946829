<template>
  <div>
    <!-- For entity file uploads, a maximum of 10 files can be uploaded parallely, using a single bulk request -->
    <FileDropzone
      :max-files="10"
      :url="fileUploadUrl"
      :upload-multiple="true"
      :is-entity-file-upload="true"
      @file-uploaded="fileUploaded"
      @all-uploaded="showCreateEntityFilesModal"
    >
      <template #droparea="{ loading }">
        <slot name="droparea" :loading="loading" />
      </template>
    </FileDropzone>

    <!--
      tw-max-h-[85%] is needed because we are using results-position-fixed for the autocomplete inputs,
      the max height prevents the results from being cut-off
    -->
    <BaseModal
      ref="createEntityFilesModal"
      title="Bestanden uploaden"
      max-width="tw-max-w-xl tw-max-h-[85%]"
      content-padding="tw-px-4 tw-py-2 md:tw-px-8 tw-py-6"
      @hide="moreInfoModalHidden"
    >
      <FormulateForm
        #default="{ isLoading }"
        name="createEntityFilesForm"
        debounce
        @submit="handleFileUpload"
      >
        <div
          v-if="uploadedFiles.length > 1"
          class="tw-pb-4 tw-px-3 tw-mr-px tw-flex tw-flex-row tw-gap-x-5 tw-justify-between tw-border-b"
        >
          <FormulateInput
            v-model="allPublic"
            debounce
            ignored
            type="toggle"
            name="public"
            label="Publiek"
            :element-class="['tw-mr-auto']"
            :wrapper-class="['tw-flex-col']"
            outer-class="tw-my-0 tw-w-14"
            @input="allModify('public', $event)"
          />
          <FormulateInput
            v-model="selectedFileType"
            debounce
            ignored
            type="autocomplete"
            auto-complete-type="fileTypes"
            name="file_type"
            label="Bestandstype"
            placeholder="Selecteer een bestandstype"
            :results-position-fixed="true"
            :show-results-on-focus="true"
            :input-class="['tw-h-8 tw-text-sm']"
            outer-class="tw-my-0 tw-flex-grow"
            @input="allModify('file_type', $event)"
          />
        </div>
        <FormulateInput
          type="group"
          name="files"
          debounce
          :value="uploadedFiles"
          :repeatable="true"
          validation="allFileTypesRequired"
          :validation-rules="{ allFileTypesRequired }"
          :validation-messages="{
            allFileTypesRequired: 'Alle bestandstypen zijn vereist'
          }"
          :error-class="['tw-text-right']"
          outer-class="tw-mb-1.5"
        >
          <template #default="{ index }">
            <div class="tw-bg-white tw-border tw-rounded tw-shadow-md tw-p-3 tw-my-4">
              <div class="tw-w-full tw-py-1.5">
                <label v-if="uploadedFiles.length === 1" class="formulate-label">
                  Naam
                </label>
                <span v-if="!fileIndexesForNameUpdate.includes(index)" class="tw-block tw-break-all tw-leading-tight tw-mb-0.5">
                  {{ uploadedFiles[index] && uploadedFiles[index].filename }} <button type="button" class="fa fa-pencil" @click="editFileName(index)"></button>
                </span>
                <FormulateInput
                  v-else
                  type="text"
                  :value="splitFileNameFromExtension(uploadedFiles[index].filename)"
                  :input-class="[
                    'tw-h-8 tw-text-sm tw-rounded-tr-none tw-rounded-br-none',
                  ]"
                  :element-class="['tw-flex tw-items-center']"
                  outer-class="tw-mt-0 tw-mb-0.5"
                  @input="handleFileNameInputChange(index, $event)"
                >
                  <div slot="suffix" class="input-unit tw-h-8">
                    <strong>.{{ splitExtensionFromFileName(uploadedFiles[index].filename) }}</strong>
                  </div>
                </FormulateInput>
              </div>

              <div
                :class="[
                  'tw-flex sm:tw-flex-row tw-gap-x-5 tw-gap-y-4 tw-justify-between',
                  { 'tw-py-1.5 tw-items-center': uploadedFiles.length > 1 }
                ]"
              >
                <FormulateInput
                  type="toggle"
                  name="public"
                  :label="uploadedFiles.length === 1 ? 'Publiek' : ''"
                  :element-class="['tw-mr-auto']"
                  :wrapper-class="['tw-flex-col']"
                  outer-class="tw-my-0 tw-w-14"
                />
                <FormulateInput
                  type="autocomplete"
                  auto-complete-type="fileTypes"
                  :show-results-on-focus="true"
                  :results-position-fixed="true"
                  name="file_type"
                  placeholder="Selecteer een bestandstype"
                  :label="uploadedFiles.length === 1 ? 'Bestandstype' : ''"
                  :input-class="[
                    'tw-h-8 tw-text-sm',
                    { 'tw-mt-0': uploadedFiles.length > 1 }
                  ]"
                  outer-class="tw-my-0 tw-flex-grow"
                />
              </div>

              <div class="tw-my-2">
                <details class="tw-cursor-pointer">
                  <summary class="tw-font-bold">Notities</summary>
                  <FormulateInput
                    name="notes"
                    type="textarea"
                    placeholder="Notities"
                    :input-class="['tw-h-14', 'tw-text-sm']"
                    outer-class="tw-my-0"
                  />
                </details>
              </div>
            </div>

          </template>
          <template #addmore />
          <template #remove />
        </FormulateInput>

        <FormulateErrors class="tw-text-right" />
        <div class="tw-flex tw-justify-end">
          <FormulateInput
            type="submit"
            :disabled="isLoading"
            outer-class="tw-mt-2"
          >
            <i
              :class="[
                'fas tw-mr-2',
                isLoading ? 'fa-spinner-third fa-spin' : 'fa-save'
              ]"
            />
            Opslaan
          </FormulateInput>
        </div>
      </FormulateForm>
    </BaseModal>
  </div>
</template>

<script>
import FileDropzone from '@/components/iam/FileDropzone'
import { successModal } from '@/modalMessages'
import { createProjectFilesBulk } from '@/services/projects'
import { createPropertyFilesBulk } from '@/services/properties'

export default {
  name: 'EntityFileUpload',
  components: { FileDropzone },
  props: {
    project: {
      type: Object,
      default: null
    },
    property: {
      type: Object,
      default: null
    },
    folder: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      uploadedFiles: [],
      selectedFileType: '',
      allPublic: false,
      fileIndexesForNameUpdate: []
    }
  },
  computed: {
    fileUploadUrl () {
      const url = this.property
        ? `/api/v3/property/${this.property.id}/files/upload`
        : `/api/v3/project/${this.project.id}/files/upload`
      return url
    },
    entityId () {
      return this.property ? this.property.id : this.project.id
    },
    createEntityFilesBulk () {
      return this.property ? createPropertyFilesBulk : createProjectFilesBulk
    }
  },
  methods: {
    showCreateEntityFilesModal () {
      this.$refs.createEntityFilesModal.show()
    },
    hideCreateEntityFilesModal () {
      this.$refs.createEntityFilesModal.hide()
    },
    moreInfoModalHidden () {
      this.uploadedFiles = []
      this.fileIndexesForNameUpdate = []
    },

    fileUploaded (response) {
      this.uploadedFiles.push(response)
    },
    allModify (key, value) {
      this.uploadedFiles.forEach(file => {
        this.$set(file, key, value)
      })
    },
    allFileTypesRequired ({ value }) {
      if (Array.isArray(value)) {
        return value.some(file => !!file.file_type?.id)
      }
      return false
    },

    splitFileNameFromExtension (fileName) {
      const substrings = fileName.split('.')
      substrings.pop()
      return substrings.join('.')
    },
    splitExtensionFromFileName (fileName) {
      return fileName.split('.').pop()
    },
    editFileName (fileIndex) {
      this.fileIndexesForNameUpdate.push(fileIndex)
    },
    handleFileNameInputChange (fileIndex, event) {
      const extension = this.uploadedFiles[fileIndex].filename.split('.').pop()
      this.$set(this.uploadedFiles[fileIndex], 'filename', `${event}.${extension}`)
    },

    refreshPropertyFiles (files) {
      const message = files.length > 1
        ? 'Bestanden zijn succesvol aangemaakt'
        : `Bestand '${files[0]?.filename} is toegevoegd`
      successModal(message)
      this.$emit('fetchFiles')
    },

    async handleFileUpload (data) {
      try {
        const files = data.files.map(file => {
          file.file_type = file.file_type.id
          if (this.folder?.id !== 'unset') file.folder_id = this.folder?.id
          return file
        })
        const response = await this.createEntityFilesBulk(this.entityId, { files })
        this.refreshPropertyFiles(response.data)
        this.hideCreateEntityFilesModal()
        this.fileIndexesForNameUpdate = []
        return response
      } catch (error) {
        this.$formulate.handle(error, 'createEntityFilesForm')
        throw error
      }
    }
  }
}
</script>
